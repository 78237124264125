import { useEffect } from 'react';
import { useRouter } from 'next/router';
export default function useHubspotCTA() {
  const router = useRouter();

  // try to refresh hubspot CTA to active hubspot-popup
  useEffect(() => {
    const routeChangeComplete = (url, { shallow }) => {
      console.log(`App is changing to ${url} ${shallow ? 'with' : 'without'} shallow routing`);
      //Update the path of the current page without rerunning getStaticProps, getServerSideProps or getInitialProps if shallow is false
      if (window.HubSpotCallsToActions && !shallow) {
        console.log(`The HubSpotCallsToActions's api is already initialized and try to refresh()`);
        window.HubSpotCallsToActions.refresh();
      }
    };

    router.events.on('routeChangeComplete', routeChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, [router]);
}
